/* eslint-disable consistent-return */
import parse from 'html-react-parser';

const useParsedData = (data) => {
  const parsedData = parse(data, {
    replace(domNode) {
      if (domNode.name === 'ul') {
        return (
          <ul className=" -ml-6  list-disc list-outside">
            {domNode.children.map((child) => (
              <li key={child.children[0].data}>{child.children[0].data}</li>
            ))}
          </ul>
        );
      }
    },
  });
  return parsedData;
};

export default useParsedData;
