const languageOptions = [
  {
    id: 93,
    name: 'JavaScript (Node.js 18.15.0)',
    label: 'JavaScript (Node.js 18.15.0)',
    value: 'javascript',
  },
  {
    id: 62,
    name: 'Java (OpenJDK 13.0.1)',
    label: 'Java (OpenJDK 13.0.1)',
    value: 'java',
  },

  {
    id: 78,
    name: 'Kotlin (1.3.70)',
    label: 'Kotlin (1.3.70)',
    value: 'kotlin',
  },
  {
    id: 70,
    name: 'Python (2.7.17)',
    label: 'Python (2.7.17)',
    value: 'python',
  },
  {
    id: 71,
    name: 'Python (3.8.1)',
    label: 'Python (3.8.1)',
    value: 'python',
  },
  {
    id: 82,
    name: 'SQL (SQLite 3.27.2)',
    label: 'SQL (SQLite 3.27.2)',
    value: 'sql',
  },
  {
    id: 74,
    name: 'TypeScript (3.7.4)',
    label: 'TypeScript (3.7.4)',
    value: 'typescript',
  },
  {
    id: 90,
    name: 'Dart (2.19.2)',
    label: 'Dart (2.19.2)',
    value: 'dart',
  },
];

export default languageOptions;
