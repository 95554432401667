import React from 'react';
import { useNavigate } from 'react-router';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

function SignupLoginNavbar() {
  const navigate = useNavigate();

  return (
    <div className="flex gap-2">
      {/* Button with text label for larger screens */}
      <button
        className="text-persian-500 px-6 py-2 border border-3 border-persian-500 rounded-3xl items-center gap-2 font-medium hidden md:flex"
        type="button"
        onClick={() => navigate('/create-account')}
      >
        Signup
        <PersonAddIcon sx={{ fontSize: 20 }} />
      </button>
      <button
        className="text-white bg-claret-500 px-6 py-2 rounded-3xl items-center gap-2 font-medium hidden md:flex"
        type="button"
        onClick={() => navigate('/login')}
      >
        Login
        <LoginIcon sx={{ fontSize: 20 }} />
      </button>

      {/* For medium-sized devices and smaller, only show icons */}
      <button
        className="text-persian-500 p-2 border border-3 border-persian-500 rounded-3xl flex items-center justify-center md:hidden"
        type="button"
        onClick={() => navigate('/create-account')}
        aria-label="Sign up"
      >
        <PersonAddIcon sx={{ fontSize: 20 }} />
      </button>
      <button
        className="text-white bg-claret-500 p-2 rounded-3xl flex items-center justify-center md:hidden"
        type="button"
        onClick={() => navigate('/login')}
        aria-label="Login"
      >
        <LoginIcon sx={{ fontSize: 20 }} />
      </button>
    </div>
  );
}

export default SignupLoginNavbar;
