import { Skeleton } from '@mui/material';

function AIChatSkeleton() {
  return (
    <div className="  flex flex-col justify-start items-start px-4 space-y-2 mb-2">
      <Skeleton
        height={15}
        className=" w-full rounded-3xl bg-gradient-to-r from-[#439DDF] to-[#D6645D] via-[#4F87ED]"
        variant="rectangular"
        sx={{ fontSize: '1rem' }}
      />
      <Skeleton
        height={15}
        className=" w-full rounded-3xl bg-gradient-to-r from-[#439DDF] to-[#D6645D] via-[#4F87ED]"
        variant="rectangular"
        sx={{ fontSize: '1rem' }}
      />
      <Skeleton
        height={15}
        className=" w-full rounded-3xl bg-gradient-to-r from-[#439DDF] to-[#D6645D] via-[#4F87ED]"
        variant="rectangular"
        sx={{ fontSize: '1rem' }}
      />
      <Skeleton
        height={15}
        className=" w-full rounded-3xl bg-gradient-to-r from-[#439DDF] to-[#D6645D] via-[#4F87ED]"
        variant="rectangular"
        sx={{ fontSize: '1rem' }}
      />
      <Skeleton
        height={15}
        className=" w-full rounded-3xl bg-gradient-to-r from-[#439DDF] to-[#D6645D] via-[#4F87ED]"
        variant="rectangular"
        sx={{ fontSize: '1rem' }}
      />
    </div>
  );
}

export default AIChatSkeleton;
