import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { axiosInterceptor } from '../utils/Axios/axiosInterceptor';

const useFetchData = (
  queryKey,
  url,
  config,
  errorMessage,
  dependency,
  noCache
) => {
  const queryFunction = async () => {
    const response = await axiosInterceptor.get(url, config);
    return response;
  };

  const { data, isLoading, error } = useQuery(queryKey, queryFunction, {
    staleTime: noCache ? '' : 3600 * 1000,
    cacheTime: noCache ? '' : 3600 * 1000,
    enabled: !!dependency || true,
    onError: () => errorMessage && toast.error(errorMessage),
  });

  return { data, isLoading, error };
};

export default useFetchData;
