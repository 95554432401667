import { Avatar } from '@mui/material';
import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TransgenderIcon from '@mui/icons-material/Transgender';

function DashboardHeader({ image, fullname, email, country, gender }) {
  return (
    <div className="bg-[#EFEFEF]/50 relative mt-[70px] flex flex-wrap justify-center md:justify-start md:item-start items-center  gap-4 pl-10 py-4 rounded-md">
      {/* <div className="    "> */}
      <Avatar
        className="rounded-full border-2 border-claret-500 -mt-[65px]"
        src={image}
        style={{ height: 125, width: 125 }}
      >
        {fullname?.toUpperCase().split(' ')[0][0]}
        {fullname?.toUpperCase().split(' ')[1][0]}
      </Avatar>
      {/* </div> */}
      <div className=" grid md:grid-cols-2 lg:grid-cols-3 w-full items-start">
        <p className=" capitalize font-roboto font-normal text-claret-500">
          {fullname}
        </p>
        {/* <p className="font-roboto font-light text-xs">{joined}</p> */}
        <div className="flex gap-1 items-center">
          <LocationOnIcon className="text-gray-800" fontSize="inherit" />

          <p className="font-roboto font-light text-sm capitalize">{country}</p>
        </div>
        <div className="flex gap-1 items-center">
          <TransgenderIcon className="text-gray-800" fontSize="inherit" />
          <p className="font-roboto font-light text-sm capitalize">{gender}</p>
        </div>
        <div className="text-sm" />
        <div className="flex gap-1 items-center">
          <EmailIcon className="text-gray-800" fontSize="inherit" />
          <p className="font-roboto font-light text-xs">{email}</p>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
