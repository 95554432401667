import React from 'react';
import { useNavigate } from 'react-router';

function DashboardEnrolledModule({ enrolled_module }) {
  const navigate = useNavigate();

  const today = new Date();

  return (
    <div className="border-[1px] border-[#C8C8C8] w-56  mx-auto h-[330px] max-h-[330px] rounded-xl overflow-hidden flex flex-col justify-between  relative mt-2">
      <img
        src={enrolled_module.image}
        alt={enrolled_module.name}
        className=" h-60 bg-cover bg-no-repeat bg-center object-cover border-b-[1px] border-[#C8C8C8]"
      />
      <div className="p-4 flex-1 flex justify-between flex-col">
        <div className="">
          <p className="text-sm font-light font-roboto text-gray-600/70">
            {enrolled_module.category}{' '}
          </p>
        </div>
        <p className="capitalize font-roboto font-medium">
          {enrolled_module?.name?.length > 20
            ? `${enrolled_module?.name?.slice(0, 20)}...`
            : enrolled_module?.name}
        </p>
        <div className="  my-2 ">
          <p className="font-roboto text-xs text-claret-500 text-right">
            {Number(enrolled_module?.completion).toFixed(2) || 0}% Complete
          </p>
          <div className="w-full bg-[#C2C2C2] h-1 rounded-lg">
            <div
              style={{ width: `${Number(enrolled_module?.completion)}%` }}
              className="bg-claret-500 h-1 rounded-lg"
            />
          </div>
        </div>
        <button
          className={`${
            enrolled_module?.is_paid_module &&
            enrolled_module?.start_date &&
            today < new Date(enrolled_module.start_date)
              ? 'text-black bg-gray-400 cursor-not-allowed'
              : 'text-white bg-claret-500 cursor-pointer'
          } text-sm px-6 py-2 rounded-3xl flex items-center gap-2 mx-auto bottom w-full align-middle justify-center capitalize font-roboto`}
          type="button"
          disabled={
            enrolled_module?.is_paid_module &&
            enrolled_module?.start_date &&
            today < new Date(enrolled_module.start_date)
          }
          onClick={() => navigate(`/course-module/${enrolled_module.id}`)}
        >
          study
        </button>
      </div>
    </div>
  );
}

export default DashboardEnrolledModule;
