import React from 'react';

function MainContent({ full, children }) {
  return (
    <div
      className={`font-roboto_light h-auto absolute px-4 top-32 pr-6 pb-6 left-[20%] extraLarge:left-[0%] extraLarge:w-full ${
        full ? 'w-4/5' : 'w-3/5 '
      } `}
    >
      {children}
    </div>
  );
}

export default MainContent;
