import { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import Spinner from '../spinner/Spinner';
import { communityInterceptor } from '../../utils/Axios/communityInterceptor';

const schema = yup
  .object({
    text_message: yup.string().required('This is a required field'),
  })
  .required();

function EditCommunityPost({ toggleModal, page, post: { message, id } }) {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      text_message: message || null,
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // handle form submit
  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const formData = new FormData();

    formData.append('text_message', data.text_message);

    communityInterceptor
      .put(`/posts/${id}`, formData)
      .then(() => {
        toast.success('Post Edited successfully!');
        queryClient.invalidateQueries(['community-posts', page]);
        queryClient.invalidateQueries(['my-posts', page]);
        toggleModal();
      })
      .catch(() => {
        toast.error(`Couldn't Edit post. Try again!`);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div>
      <form
        className="text-gray-700 space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* form inputs */}
        <div className="flex flex-col space-y-1">
          <textarea
            {...register('text_message', { required: true })}
            type="text"
            id="body"
            placeholder="Share your thoughts"
            className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
              errors.text_message ? 'border-red-600' : ''
            }`}
          />
          {errors.text_message && (
            <span className="text-red-600 text-xs mt-2">
              {errors.text_message?.message}
            </span>
          )}
        </div>

        {/* footer */}
        {isSubmitting ? (
          <Spinner />
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="submit"
              disabled={isSubmitting}
            >
              <SaveIcon fontSize="inherit" />
              <p>Edit Post</p>
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default EditCommunityPost;
