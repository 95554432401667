/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import faqs from '../utils/constants/faqs';

function FAQChat({ toggleModal }) {
  const [expandedFaq, setExpandedFaq] = useState(null);

  const toggleFaq = (id) => {
    setExpandedFaq(expandedFaq === id ? null : id);
  };

  return (
    <div className="flex flex-col space-y-2 max-h-[50vh]">
      <div className="flex flex-col space-y-2 overflow-y-auto">
        {faqs.map((faq) => (
          <div key={faq.id} className="w-full max-w-md rounded-md border">
            <div
              onClick={() => toggleFaq(faq.id)}
              className="cursor-pointer flex space-x-5 bg-gray-200 p-2"
            >
              {expandedFaq === faq.id ? (
                <p className="text-[#00999E]">-</p>
              ) : (
                <p>+</p>
              )}
              <p className=" font-light text-sm">{faq.question}</p>
            </div>
            {expandedFaq === faq.id && (
              <div className="p-2 text-sm bg-[#D0EFED]">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-between mt-7">
        <button
          className="border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center"
          type="button"
          onClick={toggleModal}
        >
          <CancelIcon fontSize="inherit" />
          <p>Cancel</p>
        </button>
      </div>
    </div>
  );
}

export default FAQChat;
