import { Avatar } from '@mui/material';
import React from 'react';
import ReactTimeAgo from 'react-time-ago';

function CommunityPostComment({ comment }) {
  return (
    <div className=" bg-gray-400/20 flex p-2 justify-between rounded-md text-justify">
      <div className=" flex items-start mb-2">
        <div className="">
          <Avatar
            src={comment.created_by.profile_image}
            sx={{ width: 40, height: 40 }}
          >
            <p className="text-sm">
              {comment.created_by.name?.toUpperCase().split(' ')[0][0]}
            </p>
            <p className="text-sm">
              {comment.created_by.name?.toUpperCase().split(' ')[1][0]}
            </p>
          </Avatar>
        </div>
        <div className=" px-2">
          <div className="text-xs  font-roboto">
            <p className="capitalize">{comment.created_by.name}</p>
            {/* <p>Student</p> */}
          </div>
          <p className="text-sm">{comment.comment}</p>
        </div>
      </div>
      <ReactTimeAgo className="text-xs" date={new Date(comment.created_at)} />
      {/* <p className="text-xs">{timeposted}</p> */}
    </div>
  );
}

export default CommunityPostComment;
