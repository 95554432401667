import { useState } from 'react';

import Popover from '@mui/material/Popover';
import { IconButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FlagIcon from '@mui/icons-material/Flag';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import useGetLoggedInUserId from '../hooks/useGetLoggedInUserId';

export default function CommunityPostPopover({
  toggleDeleteModal,
  toggleEditModal,
  toggleFlagModal,
  userId,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const loggedInUserId = useGetLoggedInUserId();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        // anchorReference="anchorPosition"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        // anchorPosition={{ top: 200, left: 400 }}
      >
        <div className="flex  align-middle items-center ">
          <List>
            {userId === loggedInUserId ? (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      toggleEditModal();
                      handleClose();
                    }}
                  >
                    <ListItemText
                      style={{ textTransform: 'capitalize', color: 'gray' }}
                      primary={
                        <div className="flex items-center gap-x-1">
                          <div>
                            <EditIcon />
                          </div>
                          Edit Post
                        </div>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      toggleDeleteModal();
                      handleClose();
                    }}
                  >
                    <ListItemText
                      style={{ textTransform: 'capitalize', color: 'gray' }}
                      primary={
                        <div className="flex items-center gap-x-1">
                          <div>
                            <DeleteIcon />
                          </div>
                          Delete Post
                        </div>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </>
            ) : (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    toggleFlagModal();
                    handleClose();
                  }}
                >
                  <ListItemText
                    style={{ textTransform: 'capitalize', color: 'gray' }}
                    primary={
                      <div className="flex items-center gap-x-1">
                        <div>
                          <FlagIcon />
                        </div>
                        Report Post
                      </div>
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}
            {/* <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleClose();
                }}
              >
                {/* <ListItemIcon>
                  <HighlightOffIcon />
                </ListItemIcon> 
                <ListItemText
                  style={{ textTransform: 'capitalize', color: 'gray' }}
                  primary={
                    <div className="flex items-center gap-x-1">
                      <div>
                        <HighlightOffIcon />
                      </div>
                      Close
                    </div>
                  }
                /> 
              </ListItemButton> 
            </ListItem>  */}
          </List>
        </div>
      </Popover>
    </div>
  );
}
