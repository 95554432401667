import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function ViewerEditor({
  initialValue,
  min_height = 300,
  max_height,
}) {
  return (
    <div className="mt-2">
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        licenseKey="gpl"
        initialValue={initialValue}
        init={{
          plugins: 'autoresize',
          external_plugins: {
            autoresize: '/tinymce/plugins/autoresize/plugin.min.js',
          },
          menubar: false,
          readonly: true,
          editable_root: false,
          autoresize_bottom_margin: 10,
          autoresize_overflow_padding: 10,
          min_height,
          ...(max_height && { max_height }), // Conditionally add max_height
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </div>
  );
}
