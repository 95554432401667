import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import useFetchDataV2 from '../hooks/useFetchDataV2';
import Spinner from './spinner/Spinner';
import ViewerEditor from './ViewerEditor';

export default function ViewAssignmentSolution({ assignmentId, toggleModal }) {
  const { data: assignmentData, isLoading: isLoadingAssignment } =
    useFetchDataV2(
      ['assignment', assignmentId],
      `/assignments/${assignmentId}`,
      {},
      "Couldn't get assignment details, please try again later",
      !!assignmentId
    );

  return (
    <div className="mt-2">
      {isLoadingAssignment && assignmentId ? (
        <div className="flex justify-center items-center w-full">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <ViewerEditor
            initialValue={assignmentData?.data?.assignment?.pseudocodeSolution}
          />
          <div className="flex justify-between">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleModal}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
