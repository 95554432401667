/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// import { toast } from 'react-hot-toast';

const initialState = {
  count: 0,
  messages: [],
};

export const notificationSlice = createSlice({
  name: 'notifcations',
  initialState,
  reducers: {
    getNotifications: (state, action) => {
      state.count = action.payload.count;
      state.messages = action.payload.messages;
    },
  },
});

export const { getNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
