import React from 'react';

function DashboardCards({ title, count, icon }) {
  return (
    <div className="border-[1px] border-[#DADADA] bg-[##FCFCFC] p-5 space-y-2 grid place-items-center">
      <div className="bg-[#D0EFED] w-fit grid place-items-center p-1 rounded-full">
        {icon}
      </div>
      <p className="capitalize text-sm font-roboto font-light">{title}</p>
      <p className=" text-base font-roboto">{count}</p>
    </div>
  );
}

export default DashboardCards;
