import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm, Controller, useController } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import Spinner from './spinner/Spinner';
import useMutateData from '../hooks/useMutateData';
// import useFetchData from '../hooks/useFetchData';
import Modal from './Modal';
import { axiosInterceptor } from '../utils/Axios/axiosInterceptor';
import { updateDetailsWithPercentage } from '../features/auth/authSlice';
import countryOptions from '../utils/constants/countryOptions';

// get currrent date
const today = new Date();

// schema for edit education
const schema = yup.object({
  institution: yup.string().required(),
  course_taken: yup
    .string()
    .max(1000, 'Title should be less than 1000 characters')
    .required(),
  level_of_education: yup.string().required(),
  is_current: yup.bool(),
  start_date: yup
    .date()
    .max(new Date(), 'Start date cannot be after today')
    .required('Start date is required'),
  end_date: yup.mixed().when(['is_current'], {
    is: false,
    then: yup
      .date()
      .required('Date is required')
      .typeError('End date must be a valid date')
      .min(yup.ref('start_date'), 'End date cannot be before start date')
      .max(today, 'end date cannot be after today'),
    otherwise: yup.string().nullable().default(null),
  }),
  city: yup.string().required('This is a required field'),
  country: yup.string().required('This is a required field'),
});

function EditEducation({
  toggleModal,
  educationId,
  userId,
  level,
  course,
  institution,
  current,
  start,
  end,
  city,
  country,
}) {
  const date = new Date(start);
  const date_end = end !== null ? new Date(end) : null;

  const year = date.getUTCFullYear();
  const month = `0${date.getUTCMonth() + 1}`.slice(-2);
  const day = `0${date.getUTCDate()}`.slice(-2);

  const year_end = date_end !== null && date_end.getUTCFullYear();
  const month_end =
    date_end !== null && `0${date_end.getUTCMonth() + 1}`.slice(-2);
  const day_end = date_end !== null && `0${date_end.getUTCDate()}`.slice(-2);

  const formattedDate = `${year}-${month}-${day}`;
  const formattedEndDate =
    date_end !== null ? `${year_end}-${month_end}-${day_end}` : null;

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      level_of_education: level || null,
      institution: institution || null,
      course_taken: course || null,
      start_date: formattedDate || null,
      is_current: current || false,
      end_date: formattedEndDate || null,
      city: city || null,
      country: country || null,
    },
  });

  const isCurrent = watch('is_current');

  // eslint-disable-next-line no-unused-vars
  const [currentStud, setCurentStud] = useState(false);

  const [endDate, setEndDate] = useState(null);

  const handleEndDateChange = (e) => {
    // eslint-disable-next-line no-unused-expressions
    if (endDate === '') {
      setEndDate(null);
    } else {
      setEndDate(e.target.value);
    }
  };

  const {
    field: { value: levelValue, onChange: levelOnChange, ...restLevelField },
  } = useController({ name: 'level_of_education', control });

  const {
    field: {
      value: countryValue,
      onChange: countryOnChange,
      ...restCountryField
    },
  } = useController({ name: 'country', control });

  const handleChange = (event) => {
    setCurentStud(event.target.checked);
  };

  const [deleteEducation, setDeleteEducation] = useState(false);

  const toggleDeleteEducation = () => {
    setDeleteEducation(!deleteEducation);
  };

  const levels = [
    { value: 'High School', label: 'High School' },
    { value: 'Diploma', label: 'Diploma' },
    { value: 'UnderGraduate', label: 'Undergraduate' },
    { value: 'PostGraduate', label: 'Postgraduate' },
  ];

  // work experience mutation
  const { mutate: addEdu, isLoading: isEditingWork } = useMutateData({
    url: `/students/education-background/${educationId}`,
    method: 'PUT',
    onSuccessfullMutation: toggleModal,
    onError: toggleModal,
    successMessage: 'Education background updated successfully',
    errorMessage: "Couldn't update education background, try again later!",
    queryKeysToInvalidate: [['user-details', userId]],
  });

  const onSubmit = async (requestData) => {
    const requestBody = {
      ...requestData,
      end_date: endDate === '' ? null : requestData.end_date,
    };

    addEdu(requestBody);
  };

  const dispatch = useDispatch();

  const baseURL =
    process.env.REACT_APP_BASE_URL ||
    'https://api.lms.v2.powerlearnprojectafrica.org/gateway/api';

  const onDeleteEducationSuccess = async () => {
    const updatedPercentage = await axiosInterceptor.get(
      `${baseURL}/users/student/${userId}`
    );

    const percentage = updatedPercentage?.data?.student?.profile_completion;
    dispatch(updateDetailsWithPercentage(percentage));
    toggleModal();
  };

  const { mutate, isLoading: isDeletingEducation } = useMutateData({
    url: `/students/education-background/${educationId}`,
    method: 'DELETE',
    onSuccessfullMutation: () => onDeleteEducationSuccess(),
    successMessage: 'Successfully Deleted Education Background',
    errorMessage: 'Failed to delete Education background, please try again',
    queryKeysToInvalidate: ['education-background', educationId],
  });

  const onDelete = async () => {
    mutate();
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="text-gray-700 space-y-4 bg-white p-6"
      >
        <div className="space-y-6">
          <div className="flex flex-col space-y-1">
            <label>Institution</label>
            <input
              {...register('institution')}
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.institution ? 'border-red-600' : ''
              }`}
            />
            {errors.institution && (
              <span className="text-red-600 text-xs mt-2">
                {errors.institution?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label>Course Taken</label>
            <input
              {...register('course_taken')}
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.course_taken ? 'border-red-600' : ''
              }`}
            />
            {errors.course_taken && (
              <span className="text-red-600 text-xs mt-2">
                {errors.course_taken?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label>Level of Education</label>
            <Controller
              name="level_of_education"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable
                  className="react-dropdown"
                  classNamePrefix="dropdown"
                  options={levels}
                  value={
                    levelValue
                      ? levels.find((x) => x.value === levelValue)
                      : levelValue
                  }
                  onChange={(option) =>
                    levelOnChange(option ? option.value : option)
                  }
                  getOptionLabel={(option) => option.label}
                  {...restLevelField}
                />
              )}
            />

            {errors.level_of_education && (
              <span className="text-red-600 text-xs mt-2">
                {errors.level_of_education?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label>City</label>
            <input
              {...register('city')}
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.city ? 'border-red-600' : ''
              }`}
            />
            {errors.city && (
              <span className="text-red-600 text-xs mt-2">
                {errors.city?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label>Country</label>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  isClearable
                  className="react-dropdown"
                  classNamePrefix="dropdown"
                  options={countryOptions}
                  value={
                    countryValue
                      ? countryOptions.find((x) => x.value === countryValue)
                      : countryValue
                  }
                  onChange={(option) =>
                    countryOnChange(option ? option.value : option)
                  }
                  getOptionLabel={(option) => option.label}
                  {...restCountryField}
                />
              )}
            />
            {errors.country && (
              <span className="text-red-600 text-xs mt-2">
                {errors.country?.message}
              </span>
            )}
          </div>
          <div className="flex flex-row space-x-6">
            <input
              type="checkbox"
              defaultChecked={current}
              onChange={handleChange}
              {...register('is_current')}
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.is_current ? 'border-red-600' : ''
              }`}
            />
            <label>Are you currently studying here?</label>
            {errors.is_current && (
              <span className="text-red-600 text-xs mt-2">
                {errors.is_current?.message}
              </span>
            )}
          </div>
          <div className="flex flex-col space-y-1">
            <label>Start date</label>
            <input
              type="date"
              {...register('start_date')}
              className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                errors.start_date ? 'border-red-600' : ''
              }`}
            />
            {errors.start_date && (
              <span className="text-red-600 text-xs mt-2">
                {errors.start_date?.message}
              </span>
            )}
          </div>
          {!isCurrent && (
            <div className="flex flex-col space-y-1">
              <label>End Date</label>
              <input
                type="date"
                {...register('end_date')}
                onChange={handleEndDateChange}
                className={`border-[#CBCBCB] border-[1px] px-2 py-2 rounded-lg focus:outline-none ${
                  errors.end_date ? 'border-red-600' : ''
                }`}
              />

              {errors.end_date && (
                <span className="text-red-600 text-xs mt-2">
                  {errors.end_date?.message}
                </span>
              )}
            </div>
          )}
          <div className=" flex justify-between mt-8">
            {isEditingWork ? (
              <div className="w-full    flex justify-center items-center">
                <Spinner />
              </div>
            ) : (
              <div className="w-full flex justify-between items-center">
                <button
                  type="button"
                  onClick={toggleDeleteEducation}
                  className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
                >
                  {' '}
                  <CancelIcon fontSize="inherit" />
                  Delete Education
                </button>
                <button
                  type="submit"
                  className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
                >
                  <SaveIcon fontSize="inherit" />
                  Save Education
                </button>
              </div>
            )}
          </div>
        </div>
      </form>

      <Modal
        title="Delete Education Background"
        modalOpen={deleteEducation}
        toggleModal={toggleDeleteEducation}
      >
        <p>Are you sure you want to delete Education Background?</p>
        {isDeletingEducation ? (
          <Spinner />
        ) : (
          <div className=" flex justify-between mt-8">
            <button
              className={`border-[1px] border-claret-500 px-4 py-1 rounded-md text-claret-500 text-sm space-x-2 flex items-center `}
              type="button"
              onClick={toggleDeleteEducation}
            >
              <CancelIcon fontSize="inherit" />
              <p>Cancel</p>
            </button>
            <button
              className={`bg-claret-500 px-4 py-1 rounded-md text-white text-sm space-x-2 flex items-center `}
              type="button"
              onClick={() => {
                onDelete();
                toggleDeleteEducation();
                toggleModal();
              }}
            >
              <SaveIcon fontSize="inherit" />
              <p>Delete Education</p>
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default EditEducation;
