import React from 'react';

function WorkAndEducationItem({
  title,
  start_date,
  end_date,
  is_current,
  place,
  image,
  city,
  country,
}) {
  const formatter = new Intl.DateTimeFormat('en-us', { month: 'short' });

  const startedOn = ` ${formatter.format(new Date(start_date))} ${new Date(
    start_date
  ).getFullYear()}`;

  const endedOn = ` ${formatter.format(new Date(end_date))} ${new Date(
    end_date
  ).getFullYear()}`;

  return (
    <div className="">
      <div className="flex gap-2">
        <div className="">
          {image ? (
            <img src={image} alt="place" />
          ) : (
            <div className=" h-12 min-h-12 min-w-12 w-12 bg-gray-500" />
          )}
        </div>
        <div className=" font-roboto text-xs w-full">
          <div className="flex justify-between gap-5">
            <h3 className="">{title}</h3>
            <p className="text-right">
              {startedOn} - {is_current ? 'Present' : endedOn}
            </p>
          </div>
          <div className="flex justify-between gap-5 text-gray-700/80 text-xs">
            <p>{place}</p>
            <p>
              {city}, {country}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkAndEducationItem;
