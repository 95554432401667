import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activate_text_to_speech:
    JSON.parse(localStorage.getItem('activate_text_to_speech')) || false,
};

export const textToSpeechSlice = createSlice({
  name: 'textToSpeech',
  initialState,
  reducers: {
    updateToggle: (state, action) => {
      // Use immutable update pattern
      const newState = { ...state, activate_text_to_speech: action.payload };
      localStorage.setItem(
        'activate_text_to_speech',
        JSON.stringify(action.payload)
      );
      return newState;
    },
  },
});

export const { updateToggle } = textToSpeechSlice.actions;

export default textToSpeechSlice.reducer;
