import React from 'react';

function CommonTopTab({ children }) {
  return (
    <div>
      <div className="flex flex-row items-center bg-white w-full p-3 rounded-sm">
        {children}
      </div>
    </div>
  );
}

export default CommonTopTab;
