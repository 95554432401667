/* eslint-disable no-param-reassign */
import axios from 'axios';
import { toast } from 'react-hot-toast';
// import { axiosInterceptor } from '../../utils/Axios/axiosInterceptor';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const baseURL =
  process.env.REACT_APP_BASE_URL ||
  'https://api.lms.v2.powerlearnprojectafrica.org/gateway/api';

const initialState = {
  isAuthenticated: !!JSON.parse(localStorage.getItem('userDetails')),
  userDetails: JSON.parse(localStorage.getItem('userDetails')) || {},
  percentage: localStorage.getItem('percentage') || 0,
  roles: [],
  // paid_modules: [],
  loading: false,
  error: '',
};

// send Login request
export const login = createAsyncThunk('auth/login', async (info) => {
  try {
    const { data } = await axios.post(`${baseURL}/auth/login/student`, info);
    localStorage.setItem('token', data.token);

    // Set the token in the axios defaults headers
    axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;

    const { data: user } = await axios.get(
      `${baseURL}/users/student/${data?.userId}`
    );

    const userDetails = {
      ...data.loggedInStudent,
      country: user?.student?.country,
      town: user?.student?.town,
    };

    localStorage.setItem('userDetails', JSON.stringify(userDetails));
    localStorage.setItem('percentage', user?.student?.profile_completion);
    localStorage.setItem('did', user?.student?.decentralized_id);

    if (data.loggedInStudent.user_type !== 'student') {
      throw Error("You're not authorized!");
    }

    toast.success('Login Successful!');
    return data;
  } catch (error) {
    localStorage.clear();

    let errorMessage = '';

    if (error.response?.status === 404) {
      errorMessage = "That account doesn't exist in our records";
    } else if (error.response?.status === 400) {
      errorMessage = error?.response?.data?.error;
    } else {
      errorMessage = error?.response?.data?.error;
    }

    throw Error(errorMessage);
  }
});
// send set password request
export const setPassword = createAsyncThunk(
  'auth/setpassword',
  async (info) => {
    try {
      const { data } = await axios.post(
        `${baseURL}/auth/student/set-password`,
        info
      );
      localStorage.setItem('token', data.token);

      // Set the token in the axios defaults headers
      axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;

      const { data: user } = await axios.get(
        `${baseURL}/users/student/${data?.userId}`
      );
      const userDetails = {
        ...data.loggedInStudent,
        country: user?.student?.country,
        town: user?.student?.town,
      };

      localStorage.setItem('userDetails', JSON.stringify(userDetails));
      localStorage.setItem('percentage', user?.student?.profile_completion);
      localStorage.setItem('did', user?.student?.decentralized_id);

      if (data.loggedInStudent.user_type !== 'student') {
        throw Error("You're not authorized!");
      }

      return data;
    } catch (error) {
      localStorage.clear();

      let errorMessage = '';

      if (error.response?.status === 404) {
        errorMessage = "That account doesn't exist in our records";
      } else if (error.response?.status === 400) {
        errorMessage = error?.response?.data?.error;
      } else {
        errorMessage = error?.response?.data?.error;
      }

      throw Error(errorMessage);
    }
  }
);

// send register student request
export const registerStudent = createAsyncThunk(
  'auth/registerStudent',
  async (info) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/student`,
        info
      );
      localStorage.setItem('token', data.token);
      localStorage.setItem('userDetails', JSON.stringify(data.loggedInStudent));
      return data;
    } catch (error) {
      localStorage.clear();
      throw Error(error.response.data);
    }
  }
);

export const addPercentageToUserDetails = createAsyncThunk(
  'auth/addPercentageToUserDetails',
  async (percentage) => {
    try {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      const updatedUserDetails = {
        ...userDetails,
        percentage,
      };

      localStorage.setItem('userDetails', JSON.stringify(updatedUserDetails));
      return updatedUserDetails;
    } catch (error) {
      throw Error('Failed to update userDetails with percentage');
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.isAuthenticated = false;
      state.userDetails = {};
      state.loading = false;
      state.roles = [];
      state.error = '';
      state.percentage = 0;
    },
    updateDetails: (state, action) => {
      localStorage.setItem('userDetails', JSON.stringify(action.payload));
      state.userDetails = action.payload;
    },
    updatePaidModules: (state, action) => {
      localStorage.setItem(
        'userDetails',
        JSON.stringify({
          ...state.userDetails,
          paid_modules: state.userDetails.paid_modules
            ? [...state.userDetails.paid_modules, action.payload]
            : [action.payload],
        })
      );
      state.userDetails.paidModules = [
        ...state.userDetails.paid_modules,
        action.payload,
      ];
    },
    updateDetailsWithPercentage: (state, action) => {
      localStorage.setItem('percentage', action.payload);
      state.percentage = action.payload;
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.isAuthenticated = false;
      state.error = '';
    },
    [login.fulfilled]: (state, action) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.userDetails = action.payload.loggedInStudent;
      state.error = '';
      state.percentage = localStorage.getItem('percentage');
    },
    [login.rejected]: (state, action) => {
      state.isAuthenticated = false;
      state.loading = false;
      state.error = action.error.message;
    },
    [setPassword.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.isAuthenticated = false;
      state.error = '';
    },
    [setPassword.fulfilled]: (state, action) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.userDetails = action.payload.loggedInStudent;
      state.error = '';
      state.percentage = localStorage.getItem('percentage');
    },
    [setPassword.rejected]: (state, action) => {
      state.isAuthenticated = false;
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export const {
  logout,
  updateDetails,
  updateDetailsWithPercentage,
  updatePaidModules,
} = authSlice.actions;

export default authSlice.reducer;
