import React from 'react';

function AboutMe({ bio }) {
  return (
    <div className="bg-[#EFEFEF]/50 p-5 rounded-md">
      <h3 className="font-roboto text-xl mb-2">About Me</h3>
      <p className="font-roboto font-light">{bio}</p>
    </div>
  );
}

export default AboutMe;
