/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Fab, Tooltip } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Modal from './Modal';
import CreateTicket from './Modals/CreateTicket';
import FAQChat from './FAQChat';

function SupportModal() {
  const [modalOpen, setModalOpen] = useState(false);
  const [supportChatModalOpen, setSupportChatModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const toggleSupportChatModal = () => {
    setSupportChatModalOpen(!supportChatModalOpen);
  };

  return (
    <div>
      <Tooltip title="Ask for help" placement="top-start">
        <Fab
          onClick={toggleSupportChatModal}
          size="small"
          sx={{ position: 'fixed', bottom: 20, right: 20 }}
          className="xs"
          color="primary"
          aria-label="help"
        >
          <QuestionAnswerIcon />
        </Fab>
        <Fab
          onClick={toggleModal}
          size="small"
          sx={{ position: 'fixed', bottom: 20, right: 80 }}
          className="xs"
          color="primary"
          aria-label="help"
        >
          <QuestionMarkIcon />
        </Fab>
      </Tooltip>
      <Modal modalOpen={modalOpen} title="How can we assist you?">
        <CreateTicket toggleModal={toggleModal} />
      </Modal>
      <Modal
        modalOpen={supportChatModalOpen}
        title="Frequently Asked Questions"
        positionClass="fixed bottom-14 right-2"
      >
        <FAQChat toggleModal={toggleSupportChatModal} />
      </Modal>
    </div>
  );
}

export default SupportModal;
