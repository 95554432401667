import { Skeleton } from '@mui/material';
import React from 'react';

function ModuleSkeleton() {
  return (
    <div className="border-[1px] border-[#C8C8C8] w-56 mx-auto h-[300px] max-h-[300px] rounded-xl overflow-hidden flex flex-col justify-between  ">
      <Skeleton variant="rectangular" width={224} height={190} />
      {/* <Skeleton variant="text" sx={{ fontSize: '0.5rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> */}
      <div className="  flex flex-col justify-start items-start px-4 space-y-2 mb-2">
        <Skeleton
          height={15}
          className=" w-4/5 rounded-3xl"
          variant="rectangular"
          sx={{ fontSize: '1rem' }}
        />
        <Skeleton
          height={15}
          className=" w-4/5 rounded-3xl"
          variant="rectangular"
          sx={{ fontSize: '1rem' }}
        />
        <Skeleton
          height={30}
          className="rounded-3xl flex items-center gap-2 bottom h-16 mb-2 align-middle justify-center w-11/12"
          variant="rectangular"
        />
      </div>
    </div>
  );
}

export default ModuleSkeleton;
