import { useState } from 'react';
import Popover from '@mui/material/Popover';
import Avatar from '@mui/material/Avatar';
import { IconButton } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';

export default function BasicPopover({
  firstName,
  lastName,
  logout,
  visitProfile,
  profilePic,
  height,
  width,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Avatar
          className="mx-auto"
          src={profilePic}
          sx={{ width: width || 30, height: height || 30 }}
        >
          <div className="text-sm capitalize">
            {firstName ? firstName[0].toUpperCase() : ''}
            {lastName ? lastName[0]?.toUpperCase() : ''}
          </div>
        </Avatar>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className="flex  align-middle items-center ">
          <List>
            <ListItem
              disablePadding
              onClick={() => {
                handleClose();
                visitProfile();
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <Avatar src="" sx={{ width: 30, height: 30 }}>
                    <div className="text-sm">
                      {firstName ? firstName[0].toUpperCase() : ''}
                      {lastName ? lastName[0].toUpperCase() : ''}
                    </div>
                  </Avatar>{' '}
                </ListItemIcon>
                <ListItemText
                  style={{ textTransform: 'capitalize' }}
                  primary={`${firstName || ''}
          ${lastName || ''}`}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding onClick={logout}>
              <ListItemButton>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText
                  style={{ textTransform: 'capitalize' }}
                  primary="logout"
                />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      </Popover>
    </div>
  );
}
